.article-list {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
}

.main-article {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.main-article-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.main-article-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.main-article-content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 30%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
    color: #fff;
}

.main-article-category {
    font-size: 0.875rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 8px;
}

.main-article-title {
    font-size: 1.5rem;
    margin: 0;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.main-article-footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #ddd;
    margin-top: 8px;
}

.main-article-author {
    font-style: italic;
}

.main-article-date {
    font-weight: bold;
}

.side-articles {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 0 0 30%;
}

@media (max-width: 1024px) {
    .article-list {
        flex-direction: column;
    }

    .side-articles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin-top: 16px;
        order: 2;
    }

    .main-article {
        order: 1;
    }

    .main-article-content {
        height: 35%;
    }
}

@media (max-width: 900px) {


    .main-article-content {
        height: 40%;
    }
}

@media (max-width: 767px) {
    .article-list {
        flex-direction: column;
    }

    .side-articles {
        grid-template-columns: 1fr;
        order: 2;
        margin-top: 16px;
    }

    .main-article-content {
        height: 50%;
    }
}

@media (max-width: 560px) {
    .article-list {
        flex-direction: column;
    }

    .side-articles {
        grid-template-columns: 1fr;
        order: 2;
        margin-top: 16px;
    }

    .main-article-content {
        height: 60%;
    }
}


.article-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.article-card {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
}

.card-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 12px;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.card-category {
    font-size: 0.75rem;
    color: #333;
    font-weight: bold;
    margin-top: 8px;
}

.card-title {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-footer {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    color: #888;
    margin-top: 8px;
}

.card-author-image {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}

.card-author-info {
    display: flex;
    flex-direction: column;
}

.card-author-name {
    font-style: italic;
}

.card-date {
    font-weight: bold;
}
