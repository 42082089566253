.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 85%;
    margin: 0 auto;
    padding-top: 15px;
    max-width: 1190px;
}

.header, .footer {
    background-color: #FAFAFA;
    color: #026191;
    padding: 10px 20px;
    box-sizing: border-box;
}

.main-content {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 20px;
    gap: 10px;
}

.left-column, .middle-column, .right-column {
    box-sizing: border-box;
    color: white;
    border-radius: 5px;
    background-color: rgba(244, 244, 244, 0.48);
}

@media (min-width: 1024px) {
    .left-column {
        flex: 0 0 calc(20% - 10px);
    }

    .middle-column {
        padding: 0 20px 20px 0;
        flex: 0 0 calc(60% - 10px);
    }

    .right-column {
        flex: 0 0 calc(20% - 10px)
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .left-column, .middle-column, .right-column {
        flex: 0 0 100%;
    }
}

@media (max-width: 767px) {
    .left-column, .middle-column, .right-column {
        flex: 0 0 100%;
    }
}

.row-welcome {
    width: 100%;
}