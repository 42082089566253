.author-profile {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 30px;
}

.author-profile h2 {
    padding-bottom: 5px;
}

.block-components {
    display: flex;
    flex-direction: row;
    color: #051829;
    gap: 10px;
    align-items: flex-start;
}

.author-image {
    width: 100px;
}

.author-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #0056b3;
}

.author-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.author-info h3 {
    margin: 0;
    padding-bottom: 5px;
}

.author-info p {
    margin: 0;
}
