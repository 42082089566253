/* src/components/SocialMediaShare.css */
.social-media-share {
    text-align: center;
    margin: 20px 0;
    height: 200px;
}

.social-media-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-items: center;
}

.social-media-grid a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.social-icon {
    font-size: 2rem;
    margin-bottom: 5px;
}

.social-media-grid span {
    font-size: 0.875rem;
}

/* Diseño para pantallas pequeñas (teléfonos) */
@media (max-width: 1024px) {
    .social-media-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .social-media-grid span {
        display: none;
    }
}
@media (max-width: 830px) {
    .social-media-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .social-media-grid span {
        display: none;
    }
}
@media (max-width: 768px) {
    .social-media-grid {
        grid-template-columns: repeat(8, 1fr);
    }

    .social-media-grid span {
        display: none;
    }
}@media (max-width: 600px) {
    .social-media-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .social-media-grid span {
        display: none;
    }
}
