/* Contenedor principal del detalle del artículo */
.article-detail-container {
    display: flex;
    width: 85%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
}

/* Estilo para la sección principal del artículo */
.article-detail-main {
    flex: 7.5;
    padding-right: 20px;
}

.article-detail-sidebar {
    padding-left: 20px;
    border-left: 1px solid #ddd;
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    flex: 3.5;
}

.article-detail-content {
    background-color: #F8F8FA;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.article-detail-content h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
}

.article-detail-content p {
    font-size: 1em;
    color: #555;
    margin-bottom: 10px;
}

.article-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.article-content {
    margin-bottom: 20px;
    white-space: pre-line;
}

.article-video {
    margin-bottom: 20px;
    position: relative;
    padding-top: 56.25%;
    height: 0;
}

.article-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.article-stats {
    font-size: 1em;
    color: #777;
}

.author-profile, .comment-navigation, .share-buttons-container, .popular-posts, .tags, .ad-block {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1px 10px 10px 20px;
    margin-bottom: 20px;
}

.author-profile h2,  .comment-navigation h2, .share-buttons-container h2, .popular-posts h2, .tags h2, .ad-block h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tags h2 {
    width: 100%;
    color: #333;
}

.tag {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    height: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.tag:hover {
    background-color: #007BFF;
    color: #fff;
}

.no-article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    text-align: center;
    padding: 20px;
    border: none;
}

.back-to-home-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.back-to-home-button:hover {
    background-color: #0056b3;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .article-detail-container {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .article-detail-container {
        flex-direction: column;
        padding: 10px;
    }

    .article-detail-sidebar {
        position: static;
        padding-left: 0;
        border-left: none;
        margin-bottom: 20px;
        width: 100%;
    }

    .article-detail-main {
        padding-right: 0;
        width: 100%;
    }
}
