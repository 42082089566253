/* ArticleMiddleCard.css */
.middle-card-link {
    text-decoration: none;
    color: inherit;
}

.middle-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.middle-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

.middle-card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.middle-card-content {
    padding: 14px;
}

.middle-card-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 8px 0;
    color: #222;
}

.middle-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.middle-card-author-image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.middle-card-author-info {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    justify-content: center;
}

.middle-card-author-name {
    font-weight: bold;
    color: #555;
}

.middle-card-date {
    font-size: 0.8rem;
    color: #888;
}

.middle-card-category {
    font-size: 0.9rem;
    font-weight: bold;
    color: #007bff;
    background-color: #e8f4ff;
    padding: 5px 10px;
    border-radius: 5px;
}
