.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-gif {
    width: 100px;
    height: 100px;
}
