.footer {
    background-color: #080C26;
    color: white;
    padding: 10px 20px;
    text-align: center;
    box-sizing: border-box; /* Esto asegura que el padding se incluya en el tamaño total */
}

.footer p {
    margin: 0;
}
