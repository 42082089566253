.comment-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.comment-item {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.comment-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.comment-name {
    font-weight: bold;
    font-size: 16px;
    color: #0d0e12;
}

.comment-date {
    font-size: 14px;
    color: #777;
}

.comment-text {
    font-size: 16px;
    color: #333;
    margin-top: 5px;
}

.divider {
    border: none;
    border-top: 1px solid #ddd;
    margin: 10px 0;
}
