.article-middle-block {
    display: flex;
    width: 100%;
    gap: 20px;

}

.article-middle-leftside {
    flex: 7;
    display: grid;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    padding-top: 20px;
}

.article-middle-rightside {
    padding: 20px;
    flex: 2.5;
    margin-left: 0;
}

.main-middle-article {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.main-middle-article-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.main-middle-article-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.main-middle-article-content {
    display: flex;
    flex-direction: column;
    height: 35%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent); /* Gradiente negro a transparente */
    color: #fff;
    padding: 20px;
}

.main-middle-article-category {
    font-size: 0.875rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 8px;
}

.main-middle-article-title {
    font-size: 1.5rem;
    margin: 0;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.main-middle-article-footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #ddd;
    padding: 20px;
}

.main-middle-article-author {
    font-style: italic;
}

.main-middle-article-date {
    font-weight: bold;
}

.side-middle-articles {
    display: grid;
    flex-direction: column;
    gap: 16px;
    flex: 0 0 30%;
    grid-template-columns: 1fr 1fr;
    margin-top: 16px;
}


@media (max-width: 767px) {
    .side-middle-articles {
        grid-template-columns: 1fr;
    }

    .main-middle-article-content {
        height: 80%;
    }
    .article-middle-block {
        flex-direction: column;
    }

    .article-middle-leftside {
        order: 1;
    }
    .article-middle-rightside {
        margin-left: 0;
        order: 2;
        padding: 20px;

    }
}
