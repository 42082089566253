/* Contenedor de la entrada de comentarios */
.input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 800px;
    margin: 0 auto;
}

/* Estilo del área de texto del editor */
.input-field {
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
    min-height: 150px;
}
.input-author-field {
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ddd;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
}

.input-field:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

/* Estilo del botón de agregar comentario */
.add-button {
    padding: 12px 25px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    align-self: flex-start; /* Alinea el botón a la izquierda */
}

.add-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.add-button:active {
    background-color: #004494;
    transform: scale(1); /* Efecto al hacer clic */
}

/* Estilo del editor de React Quill */
.ql-container.ql-snow {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.ql-editor {
    min-height: 150px; /* Asegúrate de que el editor tenga una altura mínima */
}
