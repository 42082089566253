/* Estilos generales */
.header {
    background-color: #080C26;
    color: white;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    position: relative; /* Asegura que el menú desplegable esté posicionado correctamente */
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 50px;
    margin-right: 10px;
}

.header h1 {
    margin: 0;
    font-size: large;
}

.main-nav {
    display: flex;
}

.main-nav ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.main-nav ul li {
    margin-right: 20px;
}

.main-nav ul li a {
    color: white;
    text-decoration: none;
}

.main-nav ul li a:hover {
    text-decoration: underline;
}

/* Estilos para el botón de menú (hamburguesa) */
.menu-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 1100; /* Asegura que el botón de menú esté sobre otros elementos */
}

/* Drawer menu */
.menu-drawer {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: #FAFAFA;
    box-shadow: -2px 0 5px rgba(0,0,0,0.2);
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.3s ease;
}

.menu-drawer.open {
    display: block; /* Asegura que el menú se muestre cuando esté abierto */
    transform: translateX(0);
}

.menu-drawer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu-drawer ul li {
    margin-bottom: 20px;
}

.menu-drawer ul li a {
    color: #051829;
    text-decoration: none;
    font-size: 18px;
}

.menu-drawer ul li a:hover {
    text-decoration: underline;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
    .main-nav {
        display: none;
    }

    .menu-toggle {
        display: block;
    }
}
